import { createSlice, createAction, createAsyncThunk } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

import type { RootState } from "../../store/store";

import axios from "axios";
import { activeBusinessModel } from "../../interfaces/business";
import { SET_USER_ROLE } from "../auth/auth";

const initialState: activeBusinessModel = {
  id: 0,
  role: "",
  active: false,
  businessId: 0,
  userId: 0,
  createdAt: "",
  updatedAt: "",
  deletedAt: null,
  profilePic: "",
  firstName: "",
  lastName: "",
  business: {
    addressId: 0,
    categoryId: 0,
    code: "",
    address: {
      city: "",
      createdAt: "",
      id: 0,
      latitude: "",
      longitude: "",
      postCode: "",
      state: {
        countryId: 0,
        country: {
          code: 0,
          createdAt: "",
          currency: "ngn",
          id: 0,
          name: "",
          sortName: "",
          updatedAt: "",
        },
        createdAt: "",
        id: 0,
        name: "",
        sortName: "",
        updatedAt: "",
      },
      stateId: 0,
      street: "",
      updatedAt: "",
    },
    createdAt: "",
    deletedAt: "",
    email: "",
    id: 0,
    isVerified: false,
    live: false,
    logo: "",
    name: "",
    updatedAt: "",
    userId: 0,
    subscription: {
      id: 0,
      businessId: 0,
      planId: 0,
      start: "",
      end: "",
      reccuring: false,
      active: false,
      createdAt: "",
      updatedAt: "",
      plan: {
        id: 0,
        name: "",
        sid: "",
        pricePerMonth: "",
        pricePerYear: "",
        fee: "",
        key: "",
        pricePerMonthId: "",
        pricePerYearId: "",
        createdAt: "",
        updatedAt: "",
      },
    },
    category: {
      createdAt: "",
      deletedAt: "",
      iconUri: "",
      id: 0,
      label: 0,
      maxPrice: 0,
      midPrice: 0,
      minPrice: 0,
      name: "",
      slug: null,
      updatedAt: "",
    },
  },
  user: {
    id: 0,
    firstName: "",
    lastName: "",
    email: "",
    uid: "",
    avatarUrl: "",
    phoneNumber: "",
    createdAt: "",
    updatedAt: "",
  },
};

// actions are processes that get data from backend

export const addTodo = createAction(
  "counter/add",
  function prepare(payload: number) {
    return {
      payload: {
        text: payload,
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const getMyBusiness = createAsyncThunk(
  "business/getMyBusiness",
  async (_, thunkAPI) => {
    try {
      const {
        data: { data },
      } = await axios.get(`/api/me/business`);
      return data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response);
    }
  }
);
export const activateBusiness = createAsyncThunk(
  "business/activateBusiness",
  async (businessId: number | string, thunkAPI) => {
    try {
      const {
        data: { data },
      } = await axios.put(`/api/me/business/active`, {
        businessId,
      });
      localStorage.setItem("varroe_business_id", businessId.toString());
      await thunkAPI.dispatch(getMyBusiness());
      await thunkAPI.dispatch(getactiveBusiness());
      return data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response);
    }
  }
);
export const getactiveBusiness = createAsyncThunk(
  "business/getactiveBusiness",
  async (_, thunkAPI) => {
    try {
      const {
        data: { data },
      } = await axios.get(`/api/me/business/active`);
      thunkAPI.dispatch(SET_BUSINESS(data));
      thunkAPI.dispatch(SET_USER_ROLE(data.role));
      return data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response);
    }
  }
);

export const businessSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    SET_LOGO: (state, action: PayloadAction<string>) => {
      state.business.logo = action.payload;
    },
    SET_BUSINESS: (state, action: PayloadAction<activeBusinessModel>) => {
      return { ...state, ...action.payload };
    },
    SET_ACTIVE_BUSINESS: (
      state,
      action: PayloadAction<activeBusinessModel["business"]>
    ) => {
      state.business = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(activateBusiness.fulfilled, (state, action) => {
      const response = action.payload;
      state.businessId = response.businessId;
    });
    // builder.addCase(getMyBusiness.fulfilled, (state, action) => {
    //   const response = action.payload;
    //   //   state.businessId = response.businessId;
    //   console.log("all businesses", response);
    // });
  },
});

export const { SET_LOGO, SET_BUSINESS } = businessSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectBusiness = (state: RootState) => state.business.business;
export const getBusinessCurrency = (state: RootState) =>
  state.business.business.address?.state.country.currency?.toUpperCase();

export default businessSlice.reducer;
