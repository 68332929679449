import { createSlice, createAction, createAsyncThunk } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signInWithPopup,
  signOut,
  sendPasswordResetEmail,
  confirmPasswordReset,
} from "firebase/auth";
import type { RootState } from "../../store/store";
import { user } from "../../interfaces/user";
import {
  appState,
  loginPayload,
  registerPayload,
  fireBaseLoginPayload,
} from "../../interfaces/auth";
import axios from "axios";
import { Toast } from "../../components/Base/toast";
import { auth, provider, appleProvider } from "../../config/firebase";
import { Roles } from "../../components/Navigation/NavigationItems";

const initialState: appState = {
  user: {
    first_name: "",
    last_name: "",
    email: "",
    avatarUrl: "",
    createdAt: "",
    deletedAt: null,
    id: 0,
    phoneNumber: "",
    uid: "",
    updatedAt: "",
  },
  token: "",
  role: null,
  error: null,
  firebaseToken: "",
};

// actions are processes that get data from backend

export const addTodo = createAction(
  "counter/add",
  function prepare(payload: number) {
    return {
      payload: {
        text: payload,
        createdAt: new Date().toISOString(),
      },
    };
  }
);
export const login = createAsyncThunk(
  "auth/login",
  async (loginData: loginPayload, thunkAPI) => {
    try {
      const { firstName, lastName, firebaseToken, authType } = loginData;
      const instance = axios.create({
        baseURL: process.env.REACT_APP_BASE_URL,
        headers: { "x-firebase-token": `${firebaseToken}` },
      });
      const localDoesNotHavePayload = firstName
        ? {
            firstName,
            lastName,
          }
        : {};
      const {
        data: { data },
      } = await instance.post(
        `/api/authenticate?authType=${authType}`,
        localDoesNotHavePayload
      );
      return data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response);
    }
  }
);

export const signInWithGoogleAction = createAsyncThunk(
  "auth/signInWithGoogle",
  async (_, thunkAPI) => {
    try {
      const response = await signInWithPopup(auth, provider);
      const token = await response.user.getIdToken();
      const fullName: string[] | undefined =
        response.user.displayName?.split(" ");
      if (fullName) {
        const firstName = fullName[0];
        const lastName = fullName[fullName.length - 1];
        await thunkAPI.dispatch(
          login({
            firstName,
            lastName,
            firebaseToken: token,
            authType: "google",
          })
        );
      }
      return token;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);
export const continueWithApple = createAsyncThunk(
  "auth/continueWithApple",
  async (_, thunkAPI) => {
    try {
      const result = await signInWithPopup(auth, appleProvider);
      try {
        const token = await result.user.getIdToken();
        const fullName: string[] | undefined =
          result.user.displayName?.split(" ");
        if (fullName) {
          const firstName = fullName[0];
          const lastName = fullName[fullName.length - 1];
          await thunkAPI.dispatch(
            login({
              firstName,
              lastName,
              firebaseToken: token,
              authType: "apple",
            })
          );
        }
        return token;
      } catch (error: any) {
        // Handle Errors here.
        const errorMessage = error.message;
        Toast(errorMessage, "error");
      }
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const registerFirebase = createAsyncThunk(
  "auth/registerFirebase",
  async (registerDetails: registerPayload, thunkAPI) => {
    const { registerEmail, registerPassword, firstName, lastName } =
      registerDetails;
    try {
      const user = await createUserWithEmailAndPassword(
        auth,
        registerEmail,
        registerPassword
      );
      const token = await user.user.getIdToken();
      await thunkAPI.dispatch(
        login({ firstName, lastName, firebaseToken: token, authType: "local" })
      );
      return token;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);
export const loginFirebase = createAsyncThunk(
  "auth/loginFirebase",
  async (loginDetails: fireBaseLoginPayload, thunkAPI) => {
    const { loginEmail, loginPassword } = loginDetails;
    try {
      const user = await signInWithEmailAndPassword(
        auth,
        loginEmail,
        loginPassword
      );
      const token = await user.user.getIdToken();
      await thunkAPI.dispatch(
        login({
          firstName: "",
          lastName: "",
          firebaseToken: token,
          authType: "local",
        })
      );
      return token;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);
export const forgotPassword = createAsyncThunk(
  "auth/forgotPassword",
  async (email: string) => {
    sendPasswordResetEmail(auth, email)
      .then(() => {
        // Password reset email sent!
        Toast("Reset link sent, check your email and spam", "success");
      })
      .catch((error) => {
        if (error.message.includes("Firebase: Error (auth/user-not-found).")) {
          Toast("Email not found", "error");
        }
      });
  }
);
export const changePassword = createAsyncThunk(
  "auth/changePassword",
  async (resetDetails: { code: string | null; newPassword: string }) => {
    const { code, newPassword } = resetDetails;
    let response;
    if (code) {
      try {
        await confirmPasswordReset(auth, code, newPassword);

        // Password reset email sent!
        Toast(
          "You have succesfully changed your password, you will be redirected shortly",
          "success"
        );
        response = "success";
      } catch (error: any) {
        response = error.code;
        if (error.code.includes("auth/invalid-action-code")) {
          Toast(
            "Your reset password link is invalid, please generate a new one",
            "error"
          );
        }
        if (error.code.includes("auth/expired-action-code")) {
          Toast(
            "Your reset password link has expired, please generate a new one",
            "error"
          );
        }
        if (error.code.includes("auth/user-disabled")) {
          Toast("This user has been disabled", "error");
        }
        if (error.code.includes("auth/user-not-found")) {
          Toast("This user cannot be found", "error");
        }
        if (error.code.includes("auth/weak-password")) {
          Toast("Password is weak", "error");
        }
        if (error.message.includes("Firebase: Error (auth/user-not-found).")) {
          Toast("Email not found", "error");
        }
      }
    }
    return response;
  }
);
export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    SET_USER: (state, action: PayloadAction<user>) => {
      state.user = action.payload;
    },
    SET_TOKEN: (state, action: PayloadAction<string>) => {
      state.token = action.payload;
      localStorage.setItem("varroe_token", action.payload);
    },
    REMOVE_TOKEN: (state, action: PayloadAction<string>) => {
      state.token = "";
      localStorage.removeItem("varroe_token");
    },
    SET_USER_ROLE: (state, action: PayloadAction<Roles>) => {
      state.role = action.payload;
    },
    LOGOUT(_state) {
      signOut(auth);
      localStorage.removeItem("varroe_token");
      localStorage.removeItem("merchant-firebase-token");
      localStorage.removeItem("merchant-api-token");
      localStorage.removeItem("varroe_business_id");
      localStorage.removeItem("varroe_business_type");
      localStorage.removeItem("varroe-merchant-calender-view");
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(signInWithGoogleAction.fulfilled, (state, action) => {
      localStorage.setItem("merchant-firebase-token", action.payload);
      state.firebaseToken = action.payload;
    });
    builder.addCase(continueWithApple.fulfilled, (state, action) => {
      if (action.payload) {
        localStorage.setItem("merchant-firebase-token", action.payload);
        state.firebaseToken = action.payload;
      }
    });
    builder.addCase(continueWithApple.rejected, (state, action: any) => {
      let message: string = action.payload;
      if (typeof message === "string") {
        if (message.includes("popup")) {
          message = "Popup window closed";
        }
        Toast(message, "error");
      }
    });
    builder.addCase(signInWithGoogleAction.rejected, (state, action: any) => {
      let message: string = action.payload;
      if (typeof message === "string") {
        if (message.includes("popup")) {
          message = "Popup window closed";
        }
        Toast(message, "error");
      }
    });
    builder.addCase(registerFirebase.fulfilled, (state, action) => {
      localStorage.setItem("merchant-firebase-token", action.payload);
      state.firebaseToken = action.payload;
    });
    builder.addCase(registerFirebase.rejected, (state, action) => {
      if (typeof action.payload === "string") {
        let message = action.payload;
        if (action.payload.includes("(auth/email-already-in-use)")) {
          message = "Email already exists";
        }
        Toast(message, "error");
      }
      console.error(action.payload);
    });
    builder.addCase(loginFirebase.fulfilled, (state, action) => {
      localStorage.setItem("merchant-firebase-token", action.payload);
      state.firebaseToken = action.payload;
    });
    builder.addCase(loginFirebase.rejected, (state, action) => {
      if (typeof action.payload === "string") {
        let message = action.payload;
        if (action.payload.includes("(auth/wrong-password)")) {
          message = "Wrong password";
        }
        if (
          action.payload.includes(
            "Firebase: Error (auth/network-request-failed)."
          )
        ) {
          message = "Network error, please check your internet connection";
        }
        Toast(message, "error");
      }
    });
    builder.addCase(login.pending, (state) => {
      //    loading state
    });
    builder.addCase(login.fulfilled, (state, action) => {
      const response = action.payload;
      // Toast('Login successful.', 'success');
      const userObject = {
        first_name: response.user.firstName,
        last_name: response.user.lastName,
        email: response.user.email,
        avatarUrl: response.user.avatarUrl,
        createdAt: response.user.createdAt,
        deletedAt: response.user.deletedAt,
        id: response.user.id,
        phoneNumber: response.user.phoneNumber,
        uid: response.user.uid,
        updatedAt: response.user.updatedAt,
      };
      state.user = userObject;
      state.token = response.token;
      localStorage.setItem("merchant-api-token", response.token);
    });
    builder.addCase(login.rejected, (state, action: any) => {
      const message = action.payload.data.message;
      Toast(message, "error");
      state.error = action.payload;
    });
  },
});

export const { SET_USER, SET_TOKEN, REMOVE_TOKEN, LOGOUT, SET_USER_ROLE } =
  authSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectUser = (state: RootState) => state.auth.user;

export default authSlice.reducer;
